<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-group label="Investidor" label-for="immobile">
                    <b-form-input v-model="investment.investorName" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="Imóvel" label-for="immobile">
                    <b-form-input v-model="investment.immobileName" disabled />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">
                <b-form-group label="Data da Solicitação" label-for="payment-type">
                    <flat-pickr 
                        v-model="investment.cancellationRequests.date"
                        :config="config"
                        class="form-control"
                        name="date"
                        :locale="Portuguese"
                        placeholder="Data da Solicitação" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Qtd de Cotas" label-for="payment-type">
                    <b-form-input v-model="investment.qtd" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Valor Investido" label-for="payment-type">
                  <b-form-input  :value="formatCurrencyPrice(investment.total)" disabled/>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Status" label-for="status-cota">
                    <b-form-select
                        id="status-cota"
                        v-model="investment.cancellationRequests.status"
                        label="Status da Cota"
                        :options="listStatusCota" />
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Previsão de Devolução" label-for="payment-type">
                    <flat-pickr
                        v-model="investment.cancellationRequests.dateDevolution"
                        :config="config"
                        class="form-control"
                        name="birthDate"
                        :locale="Portuguese"
                        placeholder="Previsão de Devolução" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>

        </b-row>
        <b-row style="float: right;">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mt-2 mr-1"
            @click="save">
            Salvar
          </b-button>
        </b-row>
        <b-modal class="modal-personal" size="lg" id="modal-corretor-select" hide-footer>
            <div class="d-block text-center">
            <BrokerAssociated @selected="brokerSelected"></BrokerAssociated>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {  BPagination, BFormSelect, BFormGroup, BLink, BRow, BCol, BFormInput, BModal, BButton  } from 'bootstrap-vue'
import  BrokerAssociated  from '../../checkout-oppotunity/BrokerAssociated.vue'
import InvestmentService from '@/services/Investment/InvestmentService'
import BrokerInvestmentService from '@/services/Investment/BrokerInvestmentService'
import MoneyInput from '@/components/MoneyInput.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import formatter from '@/mixins/formatter'
import toast from '@/mixins/toast'
import Ripple from 'vue-ripple-directive'
import CancelationEnum from '@/enums/CancelationEnum'
import CancellationRequestsService from '@/services/Investment/CancellationRequestsService'

export default {
    components: {
        BPagination,
        BFormSelect,
        BFormGroup,
        BButton,
        BLink,
        BRow, 
        MoneyInput,
        BCol, 
        BFormInput,
        BrokerAssociated,
        BModal,
        flatPickr
    },
    created() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    },
    destroyed() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    },
    mixins:[formatter, toast],
    directives: {
        Ripple,
    },

    data(){
        return {
            investment: { associatedBroker: {name: null, value: null, id: null, userBrokerProfileId: null, datePayment: null, status: null}},
            cancellationId: null,
            corretor: {name: null, id: null},
            listStatusCota: CancelationEnum.listEnum,
            config: {
                dateFormat: 'd/m/Y',
                locale: Portuguese,
            },
            
        }
    },

    methods: {
        async getCancellation(){
            this.investment = (await CancellationRequestsService.getCancellationRequestsForEdit(this.cancellationId)).data.result
            this.investment.cancellationRequests.date = formatter.flatPickrDateFormat(this.investment.cancellationRequests.date)
            this.investment.cancellationRequests.dateDevolution = formatter.flatPickrDateFormat(this.investment.cancellationRequests.dateDevolution)
        },

        async save(){
            try {
                await CancellationRequestsService.createOrEdit(this.investment.cancellationRequests)
                this.msgSuccess('Salvo com sucesso')
            } catch(error){
                this.msgError(error.response.data.error.message)
            }
        }
    },

    mounted() {        
        this.cancellationId = this.$route.params['id']
        this.getCancellation()
    }
}
</script>
<style>
</style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '../../sass/variables.scss';
    @import '../../sass/company';
</style>
